<template>
  <li class="wallet-item" @click="chargeItem(wallet)">
    <div class="id">
      <span class="responsive-label">Id</span> {{ wallet.id }}
    </div>
    <div class="name-icon">
      <img
        :src="`https://panel.easybitpay.com/icons/32/color/${wallet.coin.toLowerCase()}.png`"
        width="25px"
      />
      <h3 class="name">{{ wallet.name }}</h3>
    </div>
    <div class="date center Q-font">
      <span class="responsive-label">Created at</span>
      {{ convertDate(wallet.created_at, "DMMMYYYY") }}
    </div>
    <div class="balance center">
      <span class="responsive-label">Balance</span>
      {{ wallet.balance ? wallet.balance : "0" }}
    </div>
    <div class="coin center">
      <span class="responsive-label">Coin</span> {{ wallet.coin }}
    </div>
    <div class="actions center">
      <!-- <Tooltip text="Edit Wallet"
        ><button style="background: none" @click="editItem(wallet)">
          <i class="bx bx-edit bx-tada" style="color: orange"></i></button
      ></Tooltip> -->
      <Tooltip text="Charge Wallet" v-if="!wallet.commission">
        <button style="background: none" @click="chargeItem(wallet)">
          <i
            class="bx bxs-plus-circle bx-tada"
            style="color: #229861"
          ></i></button
      ></Tooltip>
      <Tooltip text="Payments of wallet" v-if="!wallet.commission">
        <button style="background: none" @click.stop="goToPayments(wallet)">
          <i
            class="bx bx-credit-card bx-tada"
            style="color: var(--color5)"
          ></i></button
      ></Tooltip>
      <Tooltip text="Transfer to wallet" v-if="wallet.commission">
        <button
          style="background: none"
          @click.stop="TransferCommission(wallet)"
        >
          <i class="bx bx-refresh bx-tada" style="color: yellow"></i></button
      ></Tooltip>
      <Tooltip text="Withdraw Money" v-if="wallet.commission">
        <button style="background: none" @click.stop="WithdrawMoney(wallet)">
          <i
            class="bx bx-money-withdraw bx-tada"
            style="color: orange"
          ></i></button
      ></Tooltip>
    </div>
  </li>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater";

export default {
  props: ["wallet"],
  mixins: [remainingTime],
  data() {
    return {};
  },
  methods: {
    chargeItem(item) {
      this.$emit("chargeItem", item);
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    goToPayments(item) {
      this.$router.push({ name: "payments", params: { id: item.id } });
    },
    WithdrawMoney(item) {
      this.$emit("withdrawMoney", item);
    },
    TransferCommission(item) {
      this.$emit("TransferCommission", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.wallet-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 5px;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 5% 33% 19% 15% 15% 10%;
  transition: all 0.1s linear;
  cursor: pointer;
  @include md {
    grid-template-columns: none;
    flex: 0 0 47.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 5px !important;
    flex: 0 0 100%;
  }
  &:hover {
    box-shadow: 0.125rem 0.125rem 0.225rem black;
    transform: scale(1.005);
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .bx {
    font-size: 1.875em;
  }
  div {
    color: #8799e0;
  }
  .name-icon {
    display: flex;
    align-items: center;
    .name {
      color: #e8eaf2;
      margin-left: 10px;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    @include md {
      justify-content: space-between;
      font-style: normal !important;
    }
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
  }
  .coin {
  }
  .actions {
    button {
      margin: 0 3px;
    }
  }

  .responsive-label {
    display: none;
    color: white;
    @include md {
      display: inline-block;
    }
  }
}
</style>
