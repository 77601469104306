<template>
  <div class="table">
    <NoResult
      v-if="!Withdraws || (!Withdraws.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="id">Id</div>
            <div class="name">Wallet</div>
            <div class="address center">Wallet address</div>
            <div class="date center">Created at</div>
            <div class="balance center">Amount</div>
            <div class="coin center">Status</div>
            <!-- <div class="actions center">Actions</div> -->
          </li>
          <WithdrawItem
            v-for="(Withdraw, i) in Withdraws"
            :key="i"
            :Withdraw="Withdraw"
          ></WithdrawItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import WithdrawItem from "./WithdrawItem.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      Withdraws: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
      itemSelected: {},
    };
  },
  components: { WithdrawItem },
  created() {
    this.fetchAllWithdraws();
  },
  methods: {
    ...mapActions(["getAllWithdraws"]),
    fetchAllWithdraws() {
      // let params = new URLSearchParams({ page: this.page, per_page: 5 });
      this.loading = true;
      this.getAllWithdraws()
        .then((res) => {
          this.Withdraws = res.data.result.data;
          this.total = res.data.result.total;
          this.lastPage = res.data.result.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addWallet() {
      this.addDialog = true;
      this.isAddDialog = true;
    },
    editWallet(item) {
      this.itemSelected = item;
      this.addDialog = true;
      this.isAddDialog = false;
    },
    SubmittedSuccessfully() {
      this.addDialog = false;
      this.fetchAllWithdraws();
    },

    chargeWallet(item) {
      this.itemSelected = item;
      this.chargeDialog = true;
    },
    chargeSubmitted() {
      this.chargeDialog = false;
      this.fetchAllWithdraws();
    },

    withdrawRequest(item) {
      this.itemSelected = item;
      this.withdrawMoneyDialog = true;
    },

    onWithdrawSubmit() {
      this.withdrawMoneyDialog = false;
    },

    changePage(val) {
      this.page = val;
      // this.fetchAllTournamets();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    grid-template-columns: 5% 15% 40% 15% 15% 10% !important;
    @include md {
      display: none !important;
    }
  }
}

.fit-height {
  height: 90vh;
}
</style>
