<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">Wallets List</h2>

        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in trTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              <!-- <i :class="`bx ${tab.icon}`"></i> -->
              {{ tab.name }}
              <span class="counts">({{ tab.count }})</span>
            </li>
          </ul>
        </div>
      </div>
      <AllWallets v-if="trTabs[0].isActive" />
      <AllWithdraws v-if="trTabs[1].isActive" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AllWallets from "../../../components/panelSections/wallets/AllWallets.vue";
import AllWithdraws from "../../../components/panelSections/wallets/AllWithdraws.vue";

export default {
  data() {
    return {
      trTabs: [
        {
          name: "Wallets",
          icon: "bx-wallet",
          isActive: true,
          count: 0,
        },
        {
          name: "Withdraw Reqs",
          icon: "bx-money-withdraw",
          isActive: false,
          count: 0,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    AllWallets,
    AllWithdraws,
  },
  computed: {},
  watch: {},
  created() {
    // get session tab name and find it and go on that tab
    if (sessionStorage.getItem("last-wallet-tr-tab")) {
      this.previousTab = this.trTabs.find(
        (element) => element.name === sessionStorage.getItem("last-wallet-tr-tab")
      );
      this.changeTabs(this.previousTab);
    }
    this.fetchAllWallets();
    this.fetchWithdraws();
  },
  methods: {
    ...mapActions(["getAllWallets", "getAllWithdraws"]),
    fetchAllWallets() {
      this.getAllWallets().then((res) => {
        this.trTabs[0].count = res.data.result.length ? res.data.result.length : 0;
      });
    },
    fetchWithdraws() {
      this.getAllWithdraws().then((res) => {
        this.trTabs[1].count = res.data.result.total ? res.data.result.total : 0;
      });
    },
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.trTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.trTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.trTabs.find((element) => element.isActive === true);
      sessionStorage.setItem("last-wallet-tr-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
.charge-btn {
  padding: 5px 0;
  border-radius: 5px;
  width: 180px;
  transition: all 0.5s ease;
}
</style>
