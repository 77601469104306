<template>
  <li class="wallet-item" @click="chargeItem(Withdraw)">
    <div class="id"><span class="responsive-label">Id</span> {{ Withdraw.id }}</div>
    <h3 class="name center">
      <span class="responsive-label">Wallet</span>
      {{ Withdraw.wallet.name }}
    </h3>
    <div class="address center Q-font">
      <span class="responsive-label">Address</span>
      {{ Withdraw.address }}
    </div>
    <div class="date center Q-font">
      <span class="responsive-label">Created at</span>
      {{ convertDate(Withdraw.created_at, "DMMMYYYY") }}
    </div>
    <div class="balance center">
      <span class="responsive-label">Amount</span>
      {{ Withdraw.amount ? Withdraw.amount : "0" }}
    </div>
    <div class="coin center">
      <span class="responsive-label">Status</span> {{ Withdraw.status }}
    </div>
    <!-- <div class="actions center">
      <Tooltip text="Charge Wallet">
        <button style="background: none" @click="chargeItem(wallet)">
          <i class="bx bxs-plus-circle bx-tada" style="color: #229861"></i></button
      ></Tooltip>
      <Tooltip text="Payments of wallet">
        <button style="background: none" @click.stop="goToPayments(wallet)">
          <i class="bx bx-credit-card bx-tada" style="color: var(--color5)"></i></button
      ></Tooltip>
      <Tooltip text="Withdraw Money">
        <button style="background: none" @click.stop="WithdrawMoney(wallet)">
          <i
            class="bx bx-money-withdraw bx-tada"
            style="color: var(--color2)"
          ></i></button
      ></Tooltip>
    </div> -->
  </li>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater";

export default {
  props: ["Withdraw"],
  mixins: [remainingTime],
  data() {
    return {};
  },
  methods: {
    // chargeItem(item) {
    //   this.$emit("chargeItem", item);
    // },
    // editItem(item) {
    //   this.$emit("editItem", item);
    // },
    // goToPayments(item) {
    //   this.$router.push({ name: "payments", params: { id: item.id } });
    // },
    // WithdrawMoney(item) {
    //   this.$emit("withdrawMoney", item);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.wallet-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 5px;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 5% 15% 40% 15% 15% 10%;
  transition: all 0.1s linear;
  cursor: pointer;
  @include md {
    grid-template-columns: none;
    flex: 0 0 47.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 5px !important;
    flex: 0 0 100%;
  }
  &:hover {
    box-shadow: 0.125rem 0.125rem 0.225rem black;
    transform: scale(1.005);
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .bx {
    font-size: 1.875em;
  }
  .name {
    color: #e8eaf2;
  }
  div {
    color: #8799e0;
  }
  .center {
    display: flex;
    justify-content: center;
    @include md {
      justify-content: space-between;
      font-style: normal !important;
    }
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
  }
  .coin {
  }
  .actions {
    button {
      margin: 0 3px;
    }
  }

  .responsive-label {
    display: none;
    color: white;
    @include md {
      display: inline-block;
    }
  }
}
</style>
