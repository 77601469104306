<template>
  <div class="add-wallet">
    <p class="info Q-font">
      <i class="bx bx-info-circle bx-tada"></i>Fill the form fields according to
      your choice and submit to send us.
    </p>
    <form @submit.prevent="submitAddWallet">
      <DropDown
        class="dp"
        :selectableItems="selectableItems"
        :required="true"
        :checkValidationFlag="checkValidationFlag"
        v-model="coin_id"
        @isValid="coin_idIsValid"
        place-holder="Coin Select"
        :isLoading="coinsListLoading"
        :disabled="!isAddDialog"
        secondName="symbol"
      ></DropDown>
      <Input
        label="Name"
        v-model="name"
        :validation="['required']"
        @isValid="nameIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="text"
        icon="bx bx-label bx-tada"
      ></Input>
      <button class="submit-btn" type="submit">
          <Loading class="loading" v-if="loading"></Loading>
        <div
          style="display: flex; justify-content: center; align-items: center"
          v-else
        >
          Submit
        </div>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    isAddDialog: {
      type: Boolean,
    },
    itemSelected: {
      type: Object,
    },
  },
  data() {
    return {
      name: null,
      nameValidation: null,
      coin_id: null,
      coin_idValidation: null,
      checkValidationFlag: false,
      loading: false,
      coinsListLoading: false,
      selectableItems: [
        // { name: "support", value: "support" },
        // { name: "technical ", value: "technical " },
        // { name: "sales ", value: "sales " },
        // { name: "financial ", value: "financial " },
      ],
    };
  },
  created() {
    this.fetchAllCoins();
    if (!this.isAddDialog) {
      this.name = this.itemSelected.name;
      this.coin_id = this.itemSelected.coin_id;
    }
  },
  methods: {
    ...mapActions(["addWallet", "editWallet", "getAllCoins"]),
    nameIsValid(v) {
      this.nameValidation = v;
    },
    coin_idIsValid(v) {
      this.coin_idValidation = v;
    },
    fetchAllCoins() {
      this.coinsListLoading = true;
      this.getAllCoins()
        .then((res) => {
          this.selectableItems = res.data.result;
        })
        .finally(() => {
          this.coinsListLoading = false;
        });
    },
    submitAddWallet() {
      
      if (this.nameValidation && this.coin_idValidation) {
        const info = {
          name: this.name,
          coin_id: this.coin_id,
        };
        this.loading = true;
        if (this.isAddDialog) {
          this.addWallet(info)
            .then((res) => {
              
              this.$emit("SubmittedSuccessfully");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          const id = this.itemSelected.id;
          this.editWallet({ id, info })
            .then((res) => {
              
              this.$emit("SubmittedSuccessfully");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-wallet {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  .info {
    color: var(--color6);
    font-size: 13px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  .dp {
    margin: 0;
    margin-bottom: 30px;
  }
  .input {
    :v-deep input {
      height: 80px;
    }
  }
  .submit-btn {
    width: 100%;
    padding: 10px 0;
    .loading {
      ::v-deep.spinner {
        div {
          background: black;
        }
      }
    }
  }
}
</style>
