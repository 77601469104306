<template>
  <div class="table">
    <NoResult
      v-if="!wallets || (!wallets.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="id">Id</div>
            <div class="name">Wallets</div>
            <div class="date center">Created at</div>
            <div class="balance center">Balance</div>
            <div class="coin center">Coin</div>
            <div class="actions center">Actions</div>
          </li>
          <WalletItem
            v-for="(wallet, i) in wallets"
            :key="i"
            :wallet="wallet"
            @chargeItem="chargeWallet"
            @editItem="editWallet"
            @withdrawMoney="withdrawMoney"
            @TransferCommission="TransferCommission"
          ></WalletItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>

    <!-- add wallet modal -->
    <BaseModal
      :dialog="addDialog"
      @close="addDialog = false"
      :title="isAddDialog ? 'Add New Wallet' : 'Edit Wallet'"
    >
      <AddWallet
        @SubmittedSuccessfully="SubmittedSuccessfully"
        :isAddDialog="isAddDialog"
        :itemSelected="itemSelected"
      ></AddWallet>
    </BaseModal>
    <!-- add wallet modal -->

    <!-- charge wallet modal -->
    <BaseModal :dialog="chargeDialog" @close="chargeDialog = false" title="Charge wallet">
      <ChargeWalletModal
        :itemSelected="itemSelected"
        @chargeSubmitted="chargeSubmitted"
      ></ChargeWalletModal>
    </BaseModal>
    <!-- charge wallet modal -->

    <!-- withdraw money modal -->
    <BaseModal
      :dialog="withdrawMoneyDialog"
      @close="withdrawMoneyDialog = false"
      title="Withdraw Commission"
    >
      <WithdrawMoney
        :itemSelected="itemSelected"
        @withdrawSubmitted="onWithdrawSubmit"
      ></WithdrawMoney>
    </BaseModal>
    <!-- withdraw money modal -->

    <!-- Transfer commission modal -->
    <BaseModal
      :dialog="transferCommissionDialog"
      @close="transferCommissionDialog = false"
      title="Commission price transfer"
    >
      <TransferToWallet
        :itemSelected="itemSelected"
        @transferSubmitted="onTransferSubmit"
      ></TransferToWallet>
    </BaseModal>
    <!-- Transfer commission modal -->
  </div>
</template>

<script>
import WalletItem from "./WalletItem.vue";
import AddWallet from "./AddWallet.vue";
import ChargeWalletModal from "./ChargeWalletModal.vue";
import WithdrawMoney from "../profile/referral/WithdrawMoney.vue";
import { mapActions } from "vuex";
import TransferToWallet from "../profile/referral/TransferToWallet.vue";
export default {
  data() {
    return {
      wallets: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
      addDialog: false,
      isAddDialog: false,
      chargeDialog: false,
      withdrawMoneyDialog: false,
      transferCommissionDialog: false,

      itemSelected: {},
    };
  },
  components: {
    WalletItem,
    AddWallet,
    ChargeWalletModal,
    WithdrawMoney,
    TransferToWallet,
  },
  created() {
    this.fetchAllWallets();
  },
  methods: {
    ...mapActions(["getAllWallets"]),
    fetchAllWallets() {
      // let params = new URLSearchParams({ page: this.page, per_page: 5 });
      this.loading = true;
      this.getAllWallets()
        .then((res) => {
          this.wallets = res.data.result;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addWallet() {
      this.addDialog = true;
      this.isAddDialog = true;
    },
    editWallet(item) {
      this.itemSelected = item;
      this.addDialog = true;
      this.isAddDialog = false;
    },
    SubmittedSuccessfully() {
      this.addDialog = false;
      this.fetchAllWallets();
    },

    chargeWallet(item) {
      this.itemSelected = item;
      this.chargeDialog = true;
    },
    chargeSubmitted() {
      this.chargeDialog = false;
      this.fetchAllWallets();
    },

    withdrawMoney(item) {
      this.itemSelected = item;
      this.withdrawMoneyDialog = true;
    },

    onWithdrawSubmit() {
      this.withdrawMoneyDialog = false;
      this.fetchAllWallets();
    },

    TransferCommission(item) {
      this.itemSelected = item;
      this.transferCommissionDialog = true;
    },
    onTransferSubmit() {
      this.transferCommissionDialog = false;
      this.fetchAllWallets();
    },

    changePage(val) {
      this.page = val;
      // this.fetchAllTournamets();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    grid-template-columns: 5% 33% 19% 15% 15% 10% !important;
    @include md {
      display: none !important;
    }
  }
}

.fit-height {
  height: 90vh;
}
</style>
