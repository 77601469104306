<template>
  <div class="charge-wallet">
    <p class="text-explation Q-font">
      <i class="bx bx-info-circle bx-tada"></i> You need to charge your wallet to be able
      to buy payable features. All payments will use your wallet balance to pay.
    </p>
    <h3 class="selected-wallet-info">
      <img
        :src="`https://panel.easybitpay.com/icons/32/color/${itemSelected.coin.toLowerCase()}.png`"
        width="25px"
      />
      {{ itemSelected.name }} ({{ itemSelected.coin }})
    </h3>
    <form action="" @submit.prevent="confirmCharge">
      <!-- <p>Login to access</p> -->
      <Input
        label="Amount"
        v-model="amount"
        :validation="['required']"
        @isValid="AmountIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="number"
        icon="bx bxs-coin-stack bx-tada"
      ></Input>
      <DropDown
        class="dp"
        :selectableItems="selectableMethods"
        :required="true"
        :checkValidationFlag="checkValidationFlag"
        v-model="payMethod"
        @isValid="payMethodIsValid"
        place-holder="Pay Method select"
        :isLoading="payListLoading"
        iconKey="icon"
      ></DropDown>

      <PanelBaseButton class="charge-btn lt-hover" type="submit">
        <Loading class="loading" v-if="loading"></Loading>
        <div v-else>
          Charge
          <i class="bx bx-cart-add bx-burst"></i>
        </div>
      </PanelBaseButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["itemSelected"],
  data() {
    return {
      loading: false,
      payListLoading: false,
      selectableMethods: [],
      amount: null,
      amountValidation: null,
      payMethod: null,
      payMethodValidation: null,
      checkValidationFlag: false,
    };
  },
  created() {
    this.fetchAllPayMethods();
  },
  methods: {
    ...mapActions(["getAllPayMethods", "chargeWallet"]),
    AmountIsValid(v) {
      this.amountValidation = v;
    },
    payMethodIsValid(v) {
      this.payMethodValidation = v;
    },
    fetchAllPayMethods() {
      this.payListLoading = true;
      this.getAllPayMethods()
        .then((res) => {
          this.selectableMethods = res.data.result;
        })
        .finally(() => {
          this.payListLoading = false;
        });
    },
    confirmCharge() {
      if (this.amountValidation && this.payMethodValidation) {
        const id = this.itemSelected.id;
        const chargeData = {
          amount: this.amount,
          gateway: this.selectableMethods.find((ele) => ele.id == this.payMethod).key,
        };
        this.loading = true;
        this.chargeWallet({ id, chargeData })
          .then((res) => {
            window.location.replace(res.data.result.url.payment_url);
          })
          .catch(() => {
            this.$emit("chargeSubmitted");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charge-wallet {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  .text-explation {
    color: white;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .selected-wallet-info {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: var(--color7);
    img {
      margin-right: 10px;
    }
  }
  .charge-btn {
    width: 100%;
    transition: all 0.5s ease;
    padding: 10px 0;
    margin-top: 20px;
    border-radius: 5px;
    .loading {
      ::v-deep.spinner {
        div {
          background: black;
        }
      }
    }
  }
}
</style>
